// @ts-ignore
import html2canvas from 'html2canvas';
import {ReImg} from 'reimg';
import {MarkerClusterer} from '@googlemaps/markerclusterer';

declare var google;

(<any>window).initGoogleMaps = () => {
    initAutocomplete();
    window.initMap('map');
}
(<any>window).html2canvas = html2canvas;

const mobileNav = () => {
    $('.c-header__hamburger').on('click', function (e) {
        e.preventDefault();

        $(this).toggleClass('c-header__hamburger--open');
        $('.c-header__nav').toggleClass('c-header__nav--active');
    });
}

const sharing = () => {
    const popup = (url: string) => {
        const {innerWidth, innerHeight} = window;
        const w = 800;
        const h = 600;
        const x = (innerWidth - w) / 2;
        const y = (innerHeight - h) / 2;
        window.open(
            url,
            '_blank',
            `width=${w},height=${h},toolbar=no,menubar=no,location=no,status=no,scrollbars=no,left=${x},top=${y}`
        );
    };

    // Instagram
    // $('.btn-instagram').on('click', (e) => {
    //     e.preventDefault();
    //     html2canvas(document.querySelector('.share-preview')).then(function (canvas) {
    //         ReImg.fromCanvas(canvas).downloadPng();
    //     });
    // });

    // Twitter
    $('.btn-twitter').on('click', function (e) {
        e.preventDefault();
        const url = $(this).attr('href')?.length > 0 ? $(this).attr('href') : window.location.toString();
        let message = $(this).data('message');
        message = `${message} ${url}`;
        popup(`https://twitter.com/intent/tweet?text=${message}`);
    });

    // Facebook
    $('.btn-facebook').on('click', function (e) {
        e.preventDefault();
        const url = $(this).attr('href')?.length > 0 ? $(this).attr('href') : window.location.toString();
        popup('https://www.facebook.com/dialog/share?' +
            'app_id=802485304092221' +
            '&display=popup' +
            `&href=${encodeURI(url)}`);
    });
};

(<any>window).initMap = (mapId) => {
    const map = new google.maps.Map(document.getElementById(mapId) as HTMLElement, {
        zoom: 3,
        mapId: 'cca06a07cb2f1149',
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
    });

    let geocoder = new google.maps.Geocoder();
    let location = window.innerWidth < 720 ? 'Australia' : 'Fiji';
    geocoder.geocode({'address': location}, function (results, status) {
        if (status == google.maps.GeocoderStatus.OK) {
            map.setCenter(results[0].geometry.location);
        } else {
            map.setCenter({lat: -17.4624892, lng: 179.2583049});
        }
    });

    // @ts-ignore
    if (typeof loadMarkers === 'function') {
        // @ts-ignore
        //new MarkerClusterer({ markers: loadMarkers(map), map });
        const markers = loadMarkers(map);

        jQuery(document).ready(() => {
            const index = Math.floor(Math.random() * markers.length);
            google.maps.event.trigger(markers[index], 'click')
            jQuery('.c-storyprev__nav--next').data('index', index);
            jQuery('.c-storyprev__nav--prev').data('index', index);

            jQuery('.c-storyprev__nav--next, .c-storyprev__nav--prev').on('click', function (event) {
                event.preventDefault();

                let index = Number.parseInt(jQuery(this).data('index'));
                if (jQuery(this).hasClass('c-storyprev__nav--next')) {
                    index++;
                    index = index > markers.length ? 0 : index;
                } else {
                    index--;
                    index = index < 0 ? markers.length - 1 : index;
                }
                console.log(index);
                google.maps.event.trigger(markers[index], 'click')

                jQuery('.c-storyprev__nav--next').data('index', index);
                jQuery('.c-storyprev__nav--prev').data('index', index);
            });
        });
    }
}

function initAutocomplete() {
    const input = document.querySelector('#dummyDoNotAutofill');
    // https://www.birdtheme.org/useful/v3tool.html
    const center = {lat: -10.472475, lng: 160.779568};
    const defaultBounds = {
        north: center.lat + 50,
        south: center.lat - 50,
        east: center.lng + 50,
        west: center.lng - 50,
    };
    const options = {
        fields: ['place_id', 'geometry', 'address_components', 'adr_address', 'name'],
        types: ['(cities)'],
        strictBounds: true,
        bounds: defaultBounds,
        //componentRestrictions: {country: ['aus', 'kir', 'nru', 'gum', 'niu', 'cok', 'asm', 'pyf', 'plw', 'mhl', 'png', 'slb', 'ncl', 'fji', 'tuv', 'ton', 'wsm', 'vut', 'fsm', 'nzl']}
    };

    const autocomplete = new google.maps.places.Autocomplete(input, options);
    autocomplete.addListener('place_changed', () => {
        const place = autocomplete.getPlace();
        if (place.geometry) {
            let state = null;
            for (const item of place.address_components) {
                if (Array.isArray(item.types) && item.types.indexOf('administrative_area_level_1') >= 0) {
                    state = item.short_name;
                }
            }

            $('#city').val(place.name + ', ' + state);
            $('#gps').val(place.geometry.location.lat() + ',' + place.geometry.location.lng());
        }
    });

    google.maps.event.addDomListener(input, 'keydown', function (event) {
        if (event.keyCode === 13) {
            event.preventDefault();
        }
    });
}

$(document).ready(function () {
    sharing();
    mobileNav();

    const storyForm = $('#storyForm');
    storyForm.submit(function (e) {
        $('#successMsg').addClass('d-none');
        $('#errorMsg').addClass('d-none');
    });

    // @ts-ignore
    $.validator.methods.email = function (value, element) {
        return this.optional(element) || /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value);
    }

    // @ts-ignore
    storyForm.validate({
        submitHandler: function (form) {
            const submitBtn = $('#submitBtn');
            const errorMsg = $('#errorMsg');

            const formData = new FormData(form);
            const sendButtonVal = submitBtn.val();

            submitBtn.val('Saving your story...');
            submitBtn.attr('disabled', 'disabled');
            $.ajax({
                url: window.location.pathname,
                type: 'POST',
                data: formData,
                success: function (data) {
                    if (data.status === 'ok') {
                        $('#successMsg').removeClass('d-none');
                        $('.form__thankyou').removeClass('d-none');
                        $('.form__form').addClass('d-none');

                        // window.initMap('mapSuccess');
                        const [file] = document.querySelector('#imagesUpload').files;
                        if (file) {
                            document.querySelector('.share-preview__image').src = URL.createObjectURL(file);
                        }

                        $('html, body').animate({
                            scrollTop: $("#shareform").offset().top
                        }, 600, 'linear');

                        form.reset();
                    } else {
                        errorMsg.removeClass('d-none');
                        errorMsg.html(data.message);
                    }
                },
                error: function (error) {
                    errorMsg.removeClass('d-none');
                    errorMsg.html(error.statusText);
                },
                complete: function () {
                    submitBtn.removeAttr('disabled');
                    submitBtn.val(sendButtonVal);
                },
                //cache: false,
                contentType: false,
                processData: false
            });

            return false;
        }
    });
});